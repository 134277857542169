import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Col, Container, Row } from 'react-bootstrap';
import showdown from 'showdown';
import LinkButton from '../../components/LinkButton/LinkButton';
import Line from '../Line/Line';
import * as styles from './products.module.scss';

const Products = ({ products }) => {
  const converter = new showdown.Converter();

  const image1 = products.image1;
  const image2 = products.image2;
  const image3 = products.image3;

  return (
    <Container>
      <Row className={styles.textSection}>
        <Col className={styles.textCol}>
          <h3 className="title">{products.title}</h3>
          <span>
            <div
              dangerouslySetInnerHTML={{
                __html: converter.makeHtml(products.paragraph)
              }}
            />
          </span>
        </Col>
      </Row>
      <Row className={styles.imageSection}>
        <Col md={4} className="text-center">
          <GatsbyImage
            image={getImage(image1)}
            alt={products.image1Alt}
            className="image"
          />
          <p className={styles.button}>
            <LinkButton path="#" title={products.image1Name} />
          </p>
        </Col>
        <Col md={4} className="text-center">
          <GatsbyImage
            image={getImage(image2)}
            alt={products.image2Alt}
            className="image"
          />
          <p className={styles.button}>
            <LinkButton path="#" title={products.image2Name} />
          </p>
        </Col>
        <Col md={4} className="text-center">
          <GatsbyImage
            image={getImage(image3)}
            alt={products.image3Alt}
            className="image"
          />
          <p className={styles.button}>
            <LinkButton path="#" title={products.image3Name} />
          </p>
        </Col>
      </Row>
      <Row className={styles.infoSection}>
        <Col className="text-center">
          <Line />
          <span>
            <div
              className={styles.infoText}
              dangerouslySetInnerHTML={{
                __html: converter.makeHtml(products.info)
              }}
            />
          </span>
          <Line />
        </Col>
      </Row>
    </Container>
  );
};

export default Products;
